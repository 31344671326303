import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { deleteListEmails, unsubscribeFromList, updateSubscriptionStatus } from './emailListsAPI';
import {
  EmailList,
  EmailListParams,
  EmailListUnsubscribeStatus,
  ListInfoPayload,
  UnsubscribeListSort,
} from './emailLists.types';
import { EmailDeleteStatus } from '../../shared.types';
import {
  setPrePaywallProgressDialog,
  openUnsubscribeSettingsDialog,
  openKeepSettingsDialog,
} from '../ui/uiSlice';
import { shouldShowPaywall } from '../../utilities/paywallHelper';
import { fetchCurrentUserInfo } from '../user/userSlice';
import { getCurrentMailbox } from '../../model/mailbox';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import AnalyticsService from '../../services/analytics/analyticsService';
import { UnsubscribeStatus } from '../../model/subscription.types';

export type ListsMap = { [mailboxId: string]: EmailLists };
export type EmailLists = { [listSender: string]: EmailList };

export interface EmailListsState {
  lists: ListsMap;
  sessionListCount?: number;
  sort: UnsubscribeListSort;
  drawerOpen: boolean;
}

export const initialState: EmailListsState = {
  lists: {},
  sort: UnsubscribeListSort.NEWEST,
  drawerOpen: false,
};

export const emailListsSlice = createSlice({
  name: 'emailLists',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUnsubscribeStatus: (
      state: EmailListsState,
      action: PayloadAction<{
        params: EmailListParams;
        unsubscribeStatus: EmailListUnsubscribeStatus;
      }>
    ) => {
      const list = getOrCreateList(state, action.payload.params);
      if (list) {
        list.unsubscribeStatus = action.payload.unsubscribeStatus;
      }
    },
    setSortOrder: (
      state,
      action: PayloadAction<{
        sortOrder: UnsubscribeListSort;
      }>
    ) => {
      state.sort = action.payload.sortOrder;
    },
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
    incrementSessionListCount: (state) => {
      if (!state.sessionListCount) {
        state.sessionListCount = 1;
      } else {
        state.sessionListCount++;
      }
    },
  },
});

export const { setUnsubscribeStatus, setSortOrder, setDrawerOpen, incrementSessionListCount } =
  emailListsSlice.actions;

export const selectNonce = (state: RootState) => state.ui.nonce;

export const selectListDeleteStatus = (
  state: RootState,
  params: EmailListParams
): EmailDeleteStatus | undefined => {
  const list = selectList(state, params);
  return list?.deleteStatus;
};

export const selectUnsubscribeStatus = (state: RootState, params: EmailListParams) => {
  const list = selectList(state, params);
  return list?.unsubscribeStatus;
};

export const selectList = (state: RootState, params: EmailListParams) => {
  const emailLists = selectLists(state, params.mailboxId);
  return emailLists?.[params.listSender];
};

export const selectLists = (state: RootState, currentMailboxId: string) => {
  const emailLists = selectListsFromListState(state.emailLists, currentMailboxId);
  return emailLists;
};

export const selectListsFromListState = (
  state: EmailListsState,
  currentMailboxId: string | undefined
): EmailLists => {
  if (!currentMailboxId) {
    // throw new Error('Current mailbox id is not set! x');
    return {};
  }
  const emailLists = state.lists[currentMailboxId];
  return emailLists;
};

export const selectListFromListState = (state: EmailListsState, params: EmailListParams) => {
  const emailLists = selectListsFromListState(state, params.mailboxId);
  return emailLists?.[params.listSender];
};

export const selectSort = (state: RootState) => state.emailLists.sort;
export const selectDrawerOpen = (state: RootState) => state.emailLists.drawerOpen;

export const selectSessionListCount = (state: RootState) => {
  return state.emailLists.sessionListCount;
};

export type ListActionAdditionalParams = {
  alsoDelete: boolean | 'use_default';
  isFromSettings: boolean;
};

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const performUnsubscribeAction =
  (
    listInfo: ListInfoPayload,
    showErrorToast: (errorMessage: string) => void,
    additionalParams: ListActionAdditionalParams
  ): AppThunk =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState) => {
    const mailbox = await getCurrentMailbox();
    if (!mailbox) {
      throw new Error('Current mailbox not found! d');
    }

    const mailboxId = mailbox.email_address;

    if (
      // If the user has not yet chosen an unsubscribe preference, show them the settings popup
      // (unless they are making a selection from the settings popup)
      !mailbox.unsubscribe_do_not_ask &&
      !additionalParams.isFromSettings
    ) {
      dispatch(
        openUnsubscribeSettingsDialog({
          mailbox: {
            email_address: mailbox.email_address,
            unsubscribe_do_not_ask: mailbox.unsubscribe_do_not_ask,
            unsubscribe_trash: mailbox.unsubscribe_trash,
          },
          listInfo,
          openedByUser: false,
        })
      );
      return;
    }

    const nonce = selectNonce(getState());

    const onUnsubscribeFailure = async (e: unknown) => {
      AnalyticsService.trackListError(EventName.UNSUBSCRIBE_FAILED, listInfo.listSender, e);

      showErrorToast('Failed to unsubscribe from list ' + listInfo.listName);
      dispatch(
        setUnsubscribeStatus({
          params: { listSender: listInfo.listSender, mailboxId },
          unsubscribeStatus: undefined,
        })
      );
      await updateSubscriptionStatus(
        { listSender: listInfo.listSender, mailboxId, nonce },
        UnsubscribeStatus.Subscribed
      );
    };

    try {
      await dispatch(fetchCurrentUserInfo());

      if (shouldShowPaywall(getState())) {
        dispatch(setPrePaywallProgressDialog(mailbox, showErrorToast));
        dispatch(
          setUnsubscribeStatus({
            params: { listSender: listInfo.listSender, mailboxId },
            unsubscribeStatus: undefined,
          })
        );
        return;
      }

      // Set the list as "Unsubscribed" in react state, so that the item animates
      dispatch(
        setUnsubscribeStatus({
          params: { listSender: listInfo.listSender, mailboxId },
          unsubscribeStatus: 'unsubscribed',
        })
      );

      // Wait 1 second to let the animation complete
      await delay(1000);

      // Set the list as "Unsubscribed" in Dexie, so that the item is removed
      await updateSubscriptionStatus(
        { listSender: listInfo.listSender, mailboxId, nonce },
        UnsubscribeStatus.Unsubscribed
      );

      // Increment count for the streak UI
      dispatch(incrementSessionListCount());

      const onSuccess = async () => {
        // AnalyticsService.trackListEvent(EventName.UNSUBSCRIBE_SUCCESSFUL, listInfo.listSender);

        const shouldDelete =
          additionalParams.alsoDelete === 'use_default'
            ? mailbox.unsubscribe_trash
            : additionalParams.alsoDelete;

        if (shouldDelete) {
          await deleteList(mailboxId, listInfo, nonce, showErrorToast);
        }
      };

      await unsubscribeFromList(
        { listSender: listInfo.listSender, mailboxId, nonce },
        { onSuccess, onError: onUnsubscribeFailure }
      );
    } catch (e) {
      await onUnsubscribeFailure(e);
    }
  };

export const performShowUnsubscribeSettingsAction =
  (listInfo: ListInfoPayload): AppThunk =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState) => {
    const currentMailbox = await getCurrentMailbox();
    if (!currentMailbox) {
      throw new Error('Current mailbox not found! e');
    }
    dispatch(
      openUnsubscribeSettingsDialog({
        mailbox: {
          email_address: currentMailbox.email_address,
          unsubscribe_do_not_ask: currentMailbox.unsubscribe_do_not_ask,
          unsubscribe_trash: currentMailbox.unsubscribe_trash,
        },
        listInfo,
        openedByUser: true,
      })
    );
  };

export const performKeepAction =
  (
    mailboxId: string,
    listInfo: ListInfoPayload,
    showErrorToast: (errorMessage: string) => void,
    additionalParams: ListActionAdditionalParams
  ): AppThunk =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState) => {
    const currentMailbox = await getCurrentMailbox();
    if (!currentMailbox) {
      throw new Error('Current mailbox not found! f');
    }

    if (
      // If the user has not yet chosen an keep preference, show them the settings popup
      // (unless they are making a selection from the settings popup)
      !currentMailbox.keep_do_not_ask &&
      !additionalParams.isFromSettings
    ) {
      dispatch(
        openKeepSettingsDialog({
          mailbox: {
            email_address: currentMailbox.email_address,
            keep_do_not_ask: currentMailbox.keep_do_not_ask,
            keep_trash: currentMailbox.keep_trash,
          },
          listInfo,
          openedByUser: false,
        })
      );
      return;
    }

    const nonce = selectNonce(getState());

    try {
      // dispatch(
      //   setUnsubscribeStatus({
      //     params: { listSender: listInfo.listSender, mailboxId },
      //     unsubscribeStatus: 'keeping',
      //   })
      // );

      dispatch(
        setUnsubscribeStatus({
          params: { listSender: listInfo.listSender, mailboxId },
          unsubscribeStatus: 'kept',
        })
      );

      dispatch(incrementSessionListCount());

      await delay(1000);

      await updateSubscriptionStatus(
        { listSender: listInfo.listSender, mailboxId, nonce },
        UnsubscribeStatus.Kept
      );

      // AnalyticsService.trackListEvent(EventName.KEEP_SUCCESSFUL, listInfo.listSender);

      const shouldDelete =
        additionalParams.alsoDelete === 'use_default'
          ? currentMailbox.keep_trash
          : additionalParams.alsoDelete;

      if (shouldDelete) {
        await deleteList(mailboxId, listInfo, nonce, showErrorToast);
      }
    } catch (e) {
      AnalyticsService.trackListError(EventName.KEEP_FAILED, listInfo.listSender, e);
      showErrorToast('Failed to keep list ' + listInfo.listName);
      dispatch(
        setUnsubscribeStatus({
          params: { listSender: listInfo.listSender, mailboxId },
          unsubscribeStatus: undefined,
        })
      );
    }
  };

export const performShowKeepSettingsAction =
  (listInfo: ListInfoPayload): AppThunk =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState) => {
    const currentMailbox = await getCurrentMailbox();
    if (!currentMailbox) {
      throw new Error('Current mailbox not found! g');
    }
    dispatch(
      openKeepSettingsDialog({
        mailbox: {
          email_address: currentMailbox.email_address,
          keep_do_not_ask: currentMailbox.keep_do_not_ask,
          keep_trash: currentMailbox.keep_trash,
        },
        listInfo,
        openedByUser: true,
      })
    );
  };

async function deleteList(
  mailboxId: string,
  listInfo: ListInfoPayload,
  nonce: string,
  showErrorToast: (errorMessage: string) => void
) {
  try {
    const onBulkDeleteFailure = (e: unknown) => {
      AnalyticsService.trackListError(EventName.DELETE_ALL_FAILED, listInfo.listSender, e);
      showErrorToast('Failed to delete all emails from list ' + listInfo.listName);
    };

    const onBulkDeleteSuccess = async (number_of_messages_deleted: number) => {
      // Disabling this high-volume event to reduce Mixpanel costs
      // AnalyticsService.trackListEvent(
      //   EventName.DELETE_ALL_SUCCESSFUL,
      //   listInfo.listSender,
      //   {
      //     messageCount: deletedMessageCount,
      //   },
      // );
    };

    // Disabling this high-volume event to reduce Mixpanel costs
    // AnalyticsService.trackListEvent(
    //   EventName.DELETE_ALL_STARTED,
    //   listInfo.listSender,
    // );

    await deleteListEmails(
      {
        listSender: listInfo.listSender,
        mailboxId,
        nonce,
      },
      { onSuccess: onBulkDeleteSuccess, onError: onBulkDeleteFailure }
    );
  } catch (e) {
    AnalyticsService.trackListError(EventName.DELETE_ALL_FAILED, listInfo.listSender, e);
    showErrorToast('Failed to delete all emails from list ' + listInfo.listName);
  }
}

function getOrCreateList(state: EmailListsState, params: EmailListParams): EmailList {
  let list = selectListFromListState(state, params);
  if (list) {
    return list;
  }

  list = {
    listSender: params.listSender,
  };

  const mailboxLists = state.lists[params.mailboxId];
  if (!mailboxLists) {
    state.lists[params.mailboxId] = {
      [params.listSender]: list,
    };
  } else {
    state.lists[params.mailboxId][params.listSender] = list;
  }

  return list;
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export default emailListsSlice.reducer;
