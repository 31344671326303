import EmailProvider from '../emailProvider';
import Mailbox from '../../model/mailbox';
import GmailEmailProvider from '../gmail/gmailEmailProvider';
import {
  EmailBundleConfig,
  SUPPORTED_EMAIL_BUNDLES,
  SUPPORTED_EMAIL_BUNDLES_IDS,
} from '../../features/emailBundles/emailBundles.configs';
import Bundle from '../../model/emailBundle';
import Message from '../../model/message';
import SyncQueryBuilder from '../syncQueryBuilder';
import GmailSyncQueryBuilder from '../gmail/syncQueryBuilder';
import retry from 'retry';
import AnalyticsService from '../analytics/analyticsService';
import { EventName } from '../analytics/providers/analyticsProvider';
import { MailboxStatus } from './mailboxSynchronizer.types';

export default class MailboxSynchronizer {
  private mailbox: Mailbox;
  private emailProvider: EmailProvider;
  private bundleCache: Map<SUPPORTED_EMAIL_BUNDLES_IDS, Bundle> = new Map();
  private syncQueryBuilder: SyncQueryBuilder = new GmailSyncQueryBuilder();

  constructor(mailbox: Mailbox) {
    this.mailbox = mailbox;
    this.emailProvider = GmailEmailProvider.create();
  }

  async sync(updateSyncStatus: (mailboxStatus: MailboxStatus) => void) {
    const operation = retry.operation();
    return new Promise<void>((resolve, reject) => {
      operation.attempt(async () => {
        try {
          AnalyticsService.track(EventName.SYNC_START);
          await this.mailbox.update({ lastSyncStartedAt: Date.now() });
          updateSyncStatus(MailboxStatus.SYNCING);
          await this.queryForMessages();
          AnalyticsService.track(EventName.SYNC_COMPLETE);
          await this.mailbox.update({ lastSyncFinishedAt: Date.now() });
          updateSyncStatus(MailboxStatus.SYNCED);
          resolve();
        } catch (error) {
          console.error(error);
          AnalyticsService.trackError(EventName.SYNC_ATTEMPT_FAILED, error);

          console.error(`[MailboxSynchronizer] sync failed `);
          if (operation.retry(error as Error)) {
            return;
          }

          AnalyticsService.trackError(EventName.SYNC_FAILED, error);
          updateSyncStatus(MailboxStatus.SYNC_FAILED);
          reject(operation.mainError());
        }
      });
    });
  }

  private async queryForMessages() {
    const query: string = await this.syncQueryBuilder.build(this.mailbox);
    const messages = await this.emailProvider.listMessages(this.mailbox, {
      query,
    });

    for await (const msg of messages) {
      try {
        if (!msg) {
          continue;
        }

        const bundles: Bundle[] = [];
        SUPPORTED_EMAIL_BUNDLES.forEach(async (bundleConfig) => {
          if (bundleConfig.criteria(msg)) {
            const bundle: Bundle = await this.getBundle(bundleConfig);
            bundles.push(bundle);
          }
        });

        if (msg.unsubscribeInfo || bundles.length > 0) {
          const message: Message = await this.mailbox.addMessage(msg);
          await message.addToBundles(bundles);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }

  private async getBundle(bundleConfig: EmailBundleConfig): Promise<Bundle> {
    let bundle = this.bundleCache.get(bundleConfig.bundleId);
    if (bundle) {
      return bundle;
    }

    bundle = await this.mailbox.findBundle(bundleConfig.bundleId);
    if (!bundle) {
      bundle = await this.mailbox.addBundle({
        display_name: bundleConfig.displayName,
        bundle_id: bundleConfig.bundleId,
        message_count: 1,
      });
    }
    this.bundleCache.set(bundleConfig.bundleId, bundle);
    return bundle;
  }
}
