import React, { useState } from 'react';

import Welcome from './Welcome/Welcome';
import EscapeWebView from './EscapeWebView/EscapeWebView';
import { useAppSelector } from '../../../app/hooks';
import { selectFailedGmailConnection, selectWebViewInfo } from '../../../features/ui/uiSlice';
import MissingScopes from './MissingScopes/MissingScopes';
import ConnectGmailPrompt from '../../ConnectGmailPrompt/ConnectGmailPrompt';
import { WEBVIEW_INVITES } from '../../../featureFlags';
import WebViewInvite from './WebViewInvite/WebViewInvite';
import SelectProvider from './SelectProvider/SelectProvider';

export type OnboardingFlowProps = {
  hasTokenAndUserInfo: boolean;
  username?: string;
};

function OnboardingFlow(props: OnboardingFlowProps) {
  const [onboardingStep, setOnboardingStep] = useState<number>(0);

  const webViewInfo = useAppSelector(selectWebViewInfo);
  const failedGmailConnection = useAppSelector(selectFailedGmailConnection);

  if (webViewInfo?.layoutConfig) {
    if (WEBVIEW_INVITES) {
      return <WebViewInvite></WebViewInvite>;
    } else {
      return (
        <EscapeWebView
          layoutConfig={webViewInfo.layoutConfig}
          deviceOS={webViewInfo.deviceOS!}
        ></EscapeWebView>
      );
    }
  }

  if (props.hasTokenAndUserInfo && props.username) {
    if (failedGmailConnection) {
      return <MissingScopes></MissingScopes>;
    } else {
      return <ConnectGmailPrompt username={props.username}></ConnectGmailPrompt>;
    }
  }

  if (onboardingStep === 0) {
    return (
      <Welcome
        next={() => {
          setOnboardingStep(1);
        }}
      ></Welcome>
    );
  }

  return <SelectProvider></SelectProvider>;

  // if (onboardingStep === 1) {
  //   return (
  //     <Privacy
  //       next={() => {
  //         setOnboardingStep(2);
  //       }}
  //     ></Privacy>
  //   );
  // }

  // return <LoginPage></LoginPage>;
}

export default OnboardingFlow;
