import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { AppThunk, RootState } from '../../app/store';
import Mailbox from '../../model/mailbox';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { WebViewInfo } from '../../services/webViewDetector/webViewDetector';
import { ListInfoPayload } from '../emailLists/emailLists.types';
import { fetchCurrentUserInfo } from '../user/userSlice';
import { PageType, ProgressDialogType, UIState } from './uiSlice.types';

export const initialState: UIState = {
  tab: 'lists',
  nonce: nanoid(),
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTab: (state, action: PayloadAction<PageType>) => {
      state.tab = action.payload;
    },
    openStudentPromoDialog: (state) => {
      state.isStudentPromoDialogOpen = true;
    },
    closeStudentPromoDialog: (state) => {
      state.isStudentPromoDialogOpen = false;
    },
    openProgressDialog: (state, action: PayloadAction<ProgressDialogType>) => {
      state.progressDialogType = action.payload;
      state.isProgressDialogOpen = true;
    },
    closeProgressDialog: (state) => {
      state.isProgressDialogOpen = false;
    },
    setPermissionsInfoDialog: (state, action: PayloadAction<boolean>) => {
      state.permissionsInfoDialog = action.payload;
    },
    openPaywallDialog: (state) => {
      state.isPaywallDialogOpen = true;
    },
    closePaywallDialog: (state) => {
      state.isPaywallDialogOpen = false;
    },
    openPlansDialog: (state) => {
      state.isPlansDialogOpen = true;
    },
    closePlansDialog: (state) => {
      state.isPlansDialogOpen = false;
    },
    openChurnDialog: (state) => {
      state.isChurnDialogOpen = true;
    },
    closeChurnDialog: (state) => {
      state.isChurnDialogOpen = false;
    },
    setHasGivenFeedback: (state) => {
      state.hasGivenFeedback = true;
    },
    openAlreadyPaidDialog: (state) => {
      state.isAlreadyPaidDialogOpen = true;
    },
    closeAlreadyPaidDialog: (state) => {
      state.isAlreadyPaidDialogOpen = false;
    },
    openRequestLicenseDialog: (state) => {
      state.isRequestLicenseDialogOpen = true;
    },
    closeRequestLicenseDialog: (state) => {
      state.isRequestLicenseDialogOpen = false;
    },
    setHasSeenProgressUpsell: (state) => {
      state.hasSeenProgressUpsell = true;
    },
    openUnsubscribeSettingsDialog: (
      state,
      action: PayloadAction<{
        mailbox: Pick<Mailbox, 'email_address' | 'unsubscribe_trash' | 'unsubscribe_do_not_ask'>;
        listInfo: ListInfoPayload;
        openedByUser: boolean;
      }>
    ) => {
      const mailbox = action.payload.mailbox;
      const openedByUser = action.payload.openedByUser;
      state.unsubscribeSettingsDialog = {
        mailboxId: mailbox.email_address,
        listInfo: action.payload.listInfo,
        useAsNewDefault: openedByUser ? false : undefined,
        moveToTrash: mailbox.unsubscribe_trash === undefined ? true : mailbox.unsubscribe_trash,
        doNotAskAgain: openedByUser
          ? undefined
          : mailbox.unsubscribe_do_not_ask === undefined
          ? true
          : mailbox.unsubscribe_do_not_ask,
      };
    },
    closeUnsubscribeSettingsDialog: (state) => {
      state.unsubscribeSettingsDialog = undefined;
    },
    openKeepSettingsDialog: (
      state,
      action: PayloadAction<{
        mailbox: Pick<Mailbox, 'email_address' | 'keep_trash' | 'keep_do_not_ask'>;
        listInfo: ListInfoPayload;
        openedByUser: boolean;
      }>
    ) => {
      const mailbox = action.payload.mailbox;
      const openedByUser = action.payload.openedByUser;

      state.keepSettingsDialog = {
        mailboxId: mailbox.email_address,
        listInfo: action.payload.listInfo,
        useAsNewDefault: openedByUser ? false : undefined,
        moveToTrash: mailbox.keep_trash === undefined ? false : mailbox.keep_trash,
        doNotAskAgain: openedByUser
          ? undefined
          : mailbox.keep_do_not_ask === undefined
          ? true
          : mailbox.keep_do_not_ask,
      };
    },
    closeKeepSettingsDialog: (state) => {
      state.keepSettingsDialog = undefined;
    },
    openUserOptionsDrawer: (state) => {
      state.userOptionsDrawer = true;
    },
    closeUserOptionsDrawer: (state) => {
      state.userOptionsDrawer = undefined;
    },
    setWebViewInfo: (state, action: PayloadAction<WebViewInfo>) => {
      state.webViewInfo = action.payload;
    },
    setFailedGmailConnection: (state, action: PayloadAction<boolean>) => {
      state.failedGmailConnection = action.payload;
    },
  },
});

export const {
  setTab,
  openStudentPromoDialog,
  closeStudentPromoDialog,
  openProgressDialog,
  closeProgressDialog,
  setPermissionsInfoDialog,
  openPaywallDialog,
  closePaywallDialog,
  openPlansDialog,
  closePlansDialog,
  openChurnDialog,
  closeChurnDialog,
  setHasGivenFeedback,
  openAlreadyPaidDialog,
  closeAlreadyPaidDialog,
  openRequestLicenseDialog,
  closeRequestLicenseDialog,
  setHasSeenProgressUpsell,
  openUnsubscribeSettingsDialog,
  closeUnsubscribeSettingsDialog,
  openKeepSettingsDialog,
  closeKeepSettingsDialog,
  openUserOptionsDrawer,
  closeUserOptionsDrawer,
  setWebViewInfo,
  setFailedGmailConnection,
} = uiSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTab = (state: RootState) => state.ui.tab;

export const selectIsProgressDialogOpen = (state: RootState) => state.ui.isProgressDialogOpen;
export const selectProgressDialogType = (state: RootState) => state.ui.progressDialogType;

export const selectPermissionsInfoDialog = (state: RootState) => state.ui.permissionsInfoDialog;

export const selectIsPaywallDialogOpen = (state: RootState) => state.ui.isPaywallDialogOpen;
export const selectIsPlansDialogOpen = (state: RootState) => state.ui.isPlansDialogOpen;
export const selectIsChurnDialogOpen = (state: RootState) => state.ui.isChurnDialogOpen;
export const selectHasGivenFeedback = (state: RootState) => state.ui.hasGivenFeedback;

export const selectIsRequestLicenseDialogOpen = (state: RootState) =>
  state.ui.isRequestLicenseDialogOpen;
export const selectIsAlreadyPaidDialogOpen = (state: RootState) => state.ui.isAlreadyPaidDialogOpen;

export const selectIsStudentPromoDialogOpen = (state: RootState) =>
  state.ui.isStudentPromoDialogOpen;

export const selectUnsubscribeSettingsDialog = (state: RootState) =>
  state.ui.unsubscribeSettingsDialog;
export const selectKeepSettingsDialog = (state: RootState) => state.ui.keepSettingsDialog;

export const selectHasSeenProgressUpsell = (state: RootState) => state.ui.hasSeenProgressUpsell;
export const selectUserOptionsDrawer = (state: RootState) => state.ui.userOptionsDrawer;

export const selectWebViewInfo = (state: RootState) => state.ui.webViewInfo;

export const selectFailedGmailConnection = (state: RootState) => state.ui.failedGmailConnection;

export default uiSlice.reducer;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const setPrePaywallProgressDialog =
  (mailbox: Mailbox | undefined, showErrorToast: (errorMessage: string) => void): AppThunk =>
  async (dispatch, getState) => {
    const hasSeenProgressUpsell = selectHasSeenProgressUpsell(getState());

    if (hasSeenProgressUpsell) {
      dispatch(openPaywallDialog());
    } else {
      dispatch(setHasSeenProgressUpsell());
      dispatch(showProgressDialog('prePaywall', mailbox, showErrorToast));
    }
  };

export const showProgressDialog =
  (
    progressDialogType: ProgressDialogType,
    mailbox: Mailbox | undefined | null,
    showErrorToast: (errorMessage: string) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      await dispatch(fetchCurrentUserInfo(true));

      dispatch(openProgressDialog(progressDialogType));
    } catch (e) {
      AnalyticsService.trackError(EventName.VIEW_PROGRESS_FAILED, e);
      showErrorToast('Something went wrong!');
    }
  };
