import React, { useCallback, useEffect } from 'react';

import { useLiveQuery } from 'dexie-react-hooks';
import { Routes, Route } from 'react-router-dom';
import { useGetURLParams } from './app/useGetURLParams';
import { useIdentification } from './app/useIdentification';
import Mailbox, { getCurrentMailboxQuery } from './model/mailbox';
import MainComponent from './MainComponent';
import LoadingPage from './components/Loading/LoadingPage';
import IntroPage from './components/intro/IntroPage';
import { useGetMailboxData } from './app/useGetMailboxData';
import { useFetchUserInfo } from './app/useFetchUserInfo';
import { useFetchOffering } from './app/useFetchOffering';
import { useCheckoutConversionLogging } from './app/useCheckoutConversionLogging';
import ABTestingService from './services/abTestingService';
import PricingPage from './screens/PricingPage/PricingPage';
import UninstallPage from './screens/UninstallPage/UninstallPage';
import LicensesPage from './screens/LicensesPage/LicensesPage';

function App() {
  useEffect(() => {
    console.log(`Version: ${process.env.REACT_APP_VERSION}`);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.TrackJS) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      TrackJS.configure({ version: process.env.REACT_APP_VERSION });
    }

    ABTestingService.initialize();
  }, []);

  const mailbox: Mailbox | undefined | null = useLiveQuery(getCurrentMailboxQuery);

  const { userEmail } = useGetURLParams();

  useIdentification(mailbox, userEmail);

  useCheckoutConversionLogging(mailbox);

  const {
    isMailboxQueryPending,
    hasTokenAndFullScopes,
    hasTokenAndUserInfoScopes,
    hasAccessToken,
  } = useGetMailboxData(mailbox);

  useFetchUserInfo(hasAccessToken, hasTokenAndFullScopes);
  useFetchOffering();

  const renderMainComponent = useCallback(() => {
    let itemToRender;
    if (isMailboxQueryPending) {
      itemToRender = <LoadingPage />;
    } else if (hasTokenAndFullScopes) {
      itemToRender = <MainComponent />;
    } else {
      itemToRender = (
        <IntroPage
          hasTokenAndUserInfo={hasTokenAndUserInfoScopes}
          username={mailbox?.first_name}
        ></IntroPage>
      );
    }
    return itemToRender;
  }, [isMailboxQueryPending, hasTokenAndFullScopes, hasTokenAndUserInfoScopes, mailbox]);

  return (
    <Routes>
      <Route path='/pricing' element={<PricingPage userEmailFromURL={userEmail} />} />
      <Route path='/uninstall' element={<UninstallPage userEmailFromURL={userEmail} />} />
      <Route path='/unlimited' element={<LicensesPage />} />
      <Route path='/' element={renderMainComponent()} />
    </Routes>
  );
}

export default App;
