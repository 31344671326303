import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import { useAppDispatch } from '../../app/hooks';
import { showProgressDialog } from '../../features/ui/uiSlice';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  STREAK_1_HEX,
  STREAK_1_TRANSPARENT_HEX,
  STREAK_2_HEX,
  STREAK_2_TRANSPARENT_HEX,
  STREAK_3_HEX,
  STREAK_3_TRANSPARENT_HEX,
} from '../../theme';
import flame from '../../assets/flame.gif';
import { NUMBER_OF_FREE_UNSUBSCRIBES } from '../../featureFlags';
import { useSnackbar } from 'notistack';
import { getCurrentMailbox } from '../../model/mailbox';
import { useTranslation } from 'react-i18next';

export type StreakBadgeProps = {
  sessionListCount: number;
};

function StreakBadge(props: StreakBadgeProps) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useAppDispatch();

  const flameSize = getFlameSize(aboveSmall, props.sessionListCount);

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const showErrorToast = (errorMessage: string) => {
    enqueueSnackbar(errorMessage, {
      variant: 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      persist: false,
    });
  };

  return (
    <Box
      sx={{
        justifyContent: aboveMedium ? 'center' : 'end',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4rem',
        borderColor: getColor(props.sessionListCount),
        backgroundColor: getColor(props.sessionListCount, true),
        height: getMaxFlameHeight(aboveSmall),
        padding: aboveMedium ? '0rem 1.5rem' : '0rem 0.75rem',
      }}
      onClick={async () => {
        const mailbox = await getCurrentMailbox();
        dispatch(showProgressDialog('streak', mailbox, showErrorToast));
      }}
    >
      <Typography
        fontSize={isExtraSmall ? '0.75rem' : aboveMedium ? '1rem' : '0.90rem'}
        sx={{
          alignSelf: 'center',
          color: getColor(props.sessionListCount),
          marginRight: '0.25rem',
          fontWeight: 500,
        }}
      >
        {t('xListStreak', {
          count: props.sessionListCount,
        })}
      </Typography>
      <Avatar
        src={flame}
        sx={{
          width: getMaxFlameWidth(aboveSmall),
          height: flameSize,
          paddingBottom: '5px',
        }}
        imgProps={{
          sx: {
            width: 'auto',
          },
        }}
      ></Avatar>
    </Box>
  );
}

function getMaxFlameHeight(aboveSmall: boolean): number {
  return aboveSmall ? 36 : 28;
}

function getMaxFlameWidth(aboveSmall: boolean): number {
  return aboveSmall ? 26 : 20;
}

function getStreakPercentage(sessionListCount: number): number {
  const maxStreak = NUMBER_OF_FREE_UNSUBSCRIBES;
  let streakPercentage = (sessionListCount || 0) / maxStreak;
  if (streakPercentage > 1) {
    streakPercentage = 1;
  }
  return streakPercentage;
}

function getFlameSize(aboveSmall: boolean, sessionListCount: number) {
  const maxFlameHeight = getMaxFlameHeight(aboveSmall);
  const minFlameHeight = maxFlameHeight * 0.4;
  const flameHeightScale = maxFlameHeight - minFlameHeight;

  const streakPercentage = getStreakPercentage(sessionListCount);

  const flameSize = minFlameHeight + flameHeightScale * streakPercentage;

  return flameSize;
}

function getColor(sessionListCount: number, transparent = false) {
  const streakPercentage = getStreakPercentage(sessionListCount);

  if (streakPercentage < 0.4) {
    if (transparent) {
      return STREAK_1_TRANSPARENT_HEX;
    } else {
      return STREAK_1_HEX;
    }
  } else if (streakPercentage < 0.7) {
    if (transparent) {
      return STREAK_2_TRANSPARENT_HEX;
    } else {
      return STREAK_2_HEX;
    }
  } else {
    if (transparent) {
      return STREAK_3_TRANSPARENT_HEX;
    } else {
      return STREAK_3_HEX;
    }
  }
}

export default StreakBadge;
