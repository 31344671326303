import React, { useEffect, useState } from 'react';

import { Button, Collapse, List, Typography } from '@mui/material';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import banner_image from '../../../../assets/banner_image.svg';
import IntroPageHeaderInfo, { LandingPageFlavorEnum } from '../components/IntroHeaderInfo';
import {
  ExampleEmailListItemProps,
  getNumberOfExampleEmailLists,
  ExampleEmailListItem,
} from './ExampleEmailList/ExampleEmailListItem';
import { MOCKED_LIST_PROPERTIES } from './ExampleEmailList/ExampleEmailList.types';
import { OnboardingStepProps } from '../tutorial.types';
import { SCOPE_TYPE } from '../../../../services/credentialFactory';
import SignInButton from '../components/SignInButton';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CONTAINER_CLASS_NAME = 'ExampleEmailListContainer';

type ExampleEmailListItemsWithoutFlavor = Omit<ExampleEmailListItemProps, 'landingPageFlavor'>[];

function Welcome(props: OnboardingStepProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [examples, setExamples] = useState<ExampleEmailListItemsWithoutFlavor>([]);
  const [exampleToUpdate, setExampleToUpdate] = useState<number>();

  const { t } = useTranslation();

  useEffect(() => {
    AnalyticsService.track(EventName.GET_STARTED_VISIT);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [landingPageFlavor, setLandingPageFlavor] = useState<LandingPageFlavorEnum>(
    LandingPageFlavorEnum.CLEAN_EMAIL
  );

  const flavorParam = searchParams.get('flavor');
  useEffect(() => {
    if (!flavorParam) {
      return;
    }

    if (Object.values(LandingPageFlavorEnum).includes(flavorParam as LandingPageFlavorEnum)) {
      // const x: LandingPageFlavorEnum =
      //   LandingPageFlavorEnum[flavorParam as keyof typeof LandingPageFlavorEnum];
      setLandingPageFlavor(flavorParam as LandingPageFlavorEnum);
    }
  }, [flavorParam]);

  // After a short delay, render as many example lists as we can fit
  useEffect(() => {
    setTimeout(() => {
      const collection = document.getElementsByClassName(CONTAINER_CLASS_NAME);
      const container = collection.item(0);

      const numberOfExampleLists = container
        ? getNumberOfExampleEmailLists(container, aboveSmall, isExtraSmall)
        : 2;

      const exampleLists: ExampleEmailListItemsWithoutFlavor = MOCKED_LIST_PROPERTIES.slice(
        0,
        numberOfExampleLists
      ).map((exampleList) => {
        return {
          ...exampleList,
          currentState: undefined,
        };
      });

      setExamples(exampleLists);

      setTimeout(() => {
        setExampleToUpdate(0);
      }, 1000);
    }, 500);
  }, []);

  // After example lists are rendered, start clicking buttons
  useEffect(() => {
    if (exampleToUpdate === undefined) {
      return;
    }
    if (exampleToUpdate >= examples.length) {
      return;
    }

    setTimeout(() => {
      const updatedExamples = examples.map((example, index) => {
        if (index == exampleToUpdate) {
          example.currentState = exampleToUpdate == 1 ? 'kept' : 'unsubscribed';
        }
        return example;
      });
      setExamples(updatedExamples);
      setExampleToUpdate(exampleToUpdate + 1);
    }, 500);
  }, [exampleToUpdate]);

  const useDirectLogin = false;

  return (
    <>
      <Box
        margin={2}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 40 : isExtraSmall ? 24 : 32,
        }}
      />
      <IntroPageHeaderInfo flavor={landingPageFlavor}></IntroPageHeaderInfo>
      <Box
        className={CONTAINER_CLASS_NAME}
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          width: '100%',
          ...(aboveSmall ? { maxWidth: '40rem' } : {}),
        }}
      >
        <List
          sx={{
            margin: aboveSmall ? '1rem 1rem 0rem 1rem' : '0.5rem 1rem 0rem 1rem',
            textAlign: 'end',
            padding: 0,
            width: '100%',
          }}
        >
          <TransitionGroup>
            {examples?.map((exampleList) => {
              return (
                <Collapse key={exampleList.email_address}>
                  <ExampleEmailListItem
                    {...exampleList}
                    landingPageFlavor={landingPageFlavor}
                  ></ExampleEmailListItem>
                </Collapse>
              );
            })}
          </TransitionGroup>
        </List>
      </Box>
      <SignInButton
        useGmailIcon={useDirectLogin}
        buttonText={t(useDirectLogin ? 'connectGmail' : 'signInWithGoogle')}
        scopeType={useDirectLogin ? SCOPE_TYPE.ALL_SCOPES : SCOPE_TYPE.USER_INFO}
      ></SignInButton>
      <Button
        variant='text'
        size='large'
        color='black'
        sx={{
          margin: aboveSmall ? '1rem' : isExtraSmall ? '0.5rem' : '0.75rem',
          marginTop: 0,
          borderRadius: '2px',
        }}
        onClick={async () => {
          AnalyticsService.track(EventName.CLICK_USE_ANOTHER_PROVIDER, { landingPageFlavor });
          props.next();
        }}
      >
        {t('useAnotherProvider')}
      </Button>
      <Typography
        variant='body2'
        sx={{
          margin: aboveSmall ? '1rem 2.5rem 2rem 2.5rem' : '1rem 2rem 1rem 2rem ',
          fontSize: isExtraSmall ? '0.75rem' : aboveSmall ? '0.85rem' : '0.75rem',
        }}
      >
        {t('signUpAgree')}
        <a className='link' href='https://www.trimbox.io/privacy'>
          {t('privacyPolicy')}
        </a>
      </Typography>
    </>
  );
}

export default Welcome;
