import { SubscriptionProperties } from '../../../../../model/subscription';

export const MOCKED_LIST_PROPERTIES: SubscriptionProperties[] = [
  {
    email_address: 'deals@kayak.com',
    display_name: 'KAYAK',
    message_count: 312,
  },
  {
    email_address: 'sales@macys.com',
    display_name: "Macy's Sale",
    message_count: 158,
  },
  {
    email_address: 'promotions@bestbuy.com',
    display_name: 'Best Buy',
    message_count: 45,
  },
  {
    email_address: 'notifications@facebook.com',
    display_name: 'Facebook',
    message_count: 47,
  },
  {
    email_address: 'hello@siriusxm.com',
    display_name: 'SiriusXM',
    message_count: 38,
  },
];
