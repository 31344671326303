import { RootState } from '../app/store';
import { NUMBER_OF_FREE_DELETES, NUMBER_OF_FREE_UNSUBSCRIBES } from '../featureFlags';
import { toMinorUnitAmount } from '../features/offering/currencyHelper';
import { PlanType, CurrencyConfig } from '../features/offering/offeringSlice.types';
import {
  selectHasLicense,
  selectNumberOfDeletes,
  selectNumberOfUnsubscribes,
} from '../features/user/userSlice';
import CheckoutSession from '../model/checkoutSession';
import Credential from '../model/credential';
import Mailbox from '../model/mailbox';
import AnalyticsService from '../services/analytics/analyticsService';
import { EventName } from '../services/analytics/providers/analyticsProvider';
import TrimboxServerClient, { UserResponse } from '../services/serverClient';

export function shouldShowPaywall(state: RootState) {
  const userHasActiveLicense = selectHasLicense(state);
  const unsubscribeCount = selectNumberOfUnsubscribes(state);
  const trashedThreadCount = selectNumberOfDeletes(state);

  if (userHasActiveLicense) {
    return false;
  }

  if ((unsubscribeCount || 0) >= NUMBER_OF_FREE_UNSUBSCRIBES) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return NUMBER_OF_FREE_UNSUBSCRIBES !== -1;
  }

  if ((trashedThreadCount || 0) >= NUMBER_OF_FREE_DELETES) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return NUMBER_OF_FREE_DELETES !== -1;
  }

  return false;
}

export function userHasActiveLicenses(user: UserResponse) {
  if (user.activeLicenses) {
    const activeLicenses = user.activeLicenses;
    for (const key in activeLicenses) {
      if (Object.prototype.hasOwnProperty.call(activeLicenses, key)) {
        const licenseStatus = activeLicenses[key];
        if (licenseStatus) {
          return true;
        }
      }
    }
  }

  return false;
}

export async function userOwnsLicense(userId: string, credential: Credential) {
  const licenses = await new TrimboxServerClient().listLicenses(userId, credential);
  return licenses.length > 0;
}

export async function userHasActiveRevenueCatEntitlement(mailbox: Mailbox) {
  const url = `https://api.revenuecat.com/v1/subscribers/${mailbox.email_address}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer appl_INwPSmPuclqEogxFBKVutCqLhSZ',
    },
  });
  const rcCustomerResponse = await response.json();

  const entitlements = rcCustomerResponse.subscriber.entitlements;
  let hasActiveEntitlements = false;

  // Get the current date in the same format as the expires_date for comparison
  const currentDate = new Date().toISOString();

  // Iterate through each entitlement to check if it's active
  for (const entitlementKey in entitlements) {
    if (Object.prototype.hasOwnProperty.call(entitlements, entitlementKey)) {
      const entitlement = entitlements[entitlementKey];

      if (entitlement.expires_date > currentDate) {
        hasActiveEntitlements = true;
        break;
      }
    }
  }

  return hasActiveEntitlements;
}

export async function openCheckoutPage({
  mailboxId,
  selectedPlan,
  currency,
  promoCode,
  returnUrl,
  source = 'web',
}: {
  mailboxId?: string;
  selectedPlan: PlanType;
  currency: CurrencyConfig;
  promoCode?: string;
  returnUrl?: string;
  source?: 'extension' | 'web' | 'mobile';
}) {
  AnalyticsService.track(EventName.PLANS_CONFIRM, {
    planInterval: selectedPlan.interval,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  fbq('track', 'InitiateCheckout');

  const client = new TrimboxServerClient();

  const amountToCharge = selectedPlan.amount;

  const minorUnitAmount = toMinorUnitAmount(amountToCharge, currency);

  const unlimitedUrl = window.location.origin + '/unlimited';

  const fullReturnURL = `${
    returnUrl || unlimitedUrl
  }?session_id={CHECKOUT_SESSION_ID}&source=${source}`;

  const checkoutSessionResponse = await client.createCheckoutSession({
    email: mailboxId,
    returnUrl: fullReturnURL,
    cancelUrl: window.location.href,
    source: source || 'web',
    code: promoCode,
    price: {
      currency: currency.code,
      amount: minorUnitAmount,
      interval: selectedPlan.interval,
    },
  });

  await CheckoutSession.create<CheckoutSession>({
    session_id: checkoutSessionResponse.sessionId,
  });

  window.location.href = checkoutSessionResponse.location;
}
