import React from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import AnalyticsService from './services/analytics/analyticsService';
import { EventName } from './services/analytics/providers/analyticsProvider';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
TimeAgo.addDefaultLocale(en);

import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationPT from './locales/pt/translation.json';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  AnalyticsService.trackError(EventName.UNKNOWN_ERROR, error);
};

i18next.init({
  resources: {
    en: { translation: translationEN },
    es: { translation: translationES },
    fr: { translation: translationFR },
    pt: { translation: translationPT },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

root.render(
  <Provider store={store}>
    <Router>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={undefined}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
              <App />
            </ErrorBoundary>
          </SnackbarProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
