import { useEffect } from 'react';
import { fetchCurrentUserInfo } from '../features/user/userSlice';
import { useAppDispatch } from './hooks';

export function useFetchUserInfo(hasAccessToken: boolean, hasTokenAndFullScopes: boolean) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (hasTokenAndFullScopes) {
      dispatch(fetchCurrentUserInfo());
    }
  }, [hasTokenAndFullScopes, hasAccessToken]);
}
