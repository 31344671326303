import React, { useEffect } from 'react';

import './IntroPage.scss';
import { Stack } from '@mui/material';
import { GHOST_WHITE_HEX } from '../../theme';
import OnboardingFlow from './onboarding/OnboardingFlow';
import { WebViewDetector } from '../../services/webViewDetector/webViewDetector';

export type IntroPageProps = {
  hasTokenAndUserInfo: boolean;
  username?: string;
};

function IntroPage(props: IntroPageProps) {
  const vh = window.innerHeight * 0.01;

  // Set the CSS variable to the root element
  // Which is equal to 1vh
  document.documentElement.style.setProperty('--vh', vh + 'px');

  useEffect(() => {
    const webViewInfo = WebViewDetector.checkForWebView();
    if (webViewInfo?.isInApp) {
      window.location.href = webViewInfo.appStoreLink;
    }
  }, []);

  return (
    <Stack
      direction='column'
      alignItems='center'
      className='full-height-stack blue-gradient'
      sx={{
        backgroundColor: GHOST_WHITE_HEX,
      }}
    >
      <OnboardingFlow
        hasTokenAndUserInfo={props.hasTokenAndUserInfo}
        username={props.username}
      ></OnboardingFlow>
    </Stack>
  );
}
export default IntroPage;
