import { useAppSelector } from './hooks';
import { selectOffering, selectSelectedPlanInterval } from '../features/offering/offeringSlice';
import {
  PlanIntervalConfig,
  PlanInterval,
  PlanType,
  PlanTypePresentable,
  getAmountWithDiscount,
} from '../features/offering/offeringSlice.types';
import {
  comparisonPrice,
  convertPrice,
  getTotalDueAmounts,
} from '../features/offering/plans.helpers';
import { useGetAvailablePromo } from './useGetAvailablePromo';
import Mailbox from '../model/mailbox';

type OfferingParams = {
  includeFree?: boolean;
  sortByAscending?: boolean;
  mailbox: Mailbox | null | undefined;
};

export function useGetOffering(params: OfferingParams) {
  const { includeFree, sortByAscending, mailbox } = params;

  const offering = useAppSelector(selectOffering);
  const selectedPlanInterval = useAppSelector(selectSelectedPlanInterval);

  // const [isFacebookUser] = useState(localStorage.getItem(UTM_SOURCE_KEY) === 'Facebook');

  // TODO - enable this test once web ads stabilize
  // const lifetimePlanTest: ABTest | undefined = useLiveQuery(() =>
  //   getTestValueQuery(PRICE_TEST_LIFETIME_PLAN)
  // );
  // const isLifetimePlanTestActive = lifetimePlanTest?.test_value === A_GROUP;

  const { promoCode, discountPercentage, withLifetimeOffer } = useGetAvailablePromo(mailbox);

  let plans: PlanType[] | undefined = undefined;
  if (offering?.plans) {
    plans = offering.plans.map((plan) => {
      if (withLifetimeOffer && plan.interval === 'year') {
        return {
          ...plan,
          interval: 'lifetime',
        };
      }
      return {
        ...plan,
      };
    });
  }

  if (includeFree && plans?.length == 2) {
    plans.push({
      label: 'default',
      amount: 0,
      interval: 'free',
    });
  }

  if (plans?.length) {
    if (sortByAscending) {
      plans.sort((a, b) => a.amount - b.amount);
    } else {
      plans.sort((a, b) => b.amount - a.amount);
    }
  }

  const presentablePlans: PlanTypePresentable[] | undefined = plans?.map((plan) => {
    const offeringConfig = PlanIntervalConfigMap[plan.interval];

    const priceIntervalToShow: PlanInterval = plan.interval == 'lifetime' ? 'lifetime' : 'month';

    // This applies the highest per-day price (eg the monthly plan price) to the current interval (eg the whole year for a yearly plan)
    const maximizedPrice = comparisonPrice(plan, priceIntervalToShow, plans!, undefined);

    const presentedPrice = convertPrice(plan, priceIntervalToShow, discountPercentage);

    const savingPerMonth = maximizedPrice - presentedPrice;
    const discount = (savingPerMonth / maximizedPrice) * 100;
    const presentedSavings = Math.round(discount);

    const presentablePlan: PlanTypePresentable = {
      ...offeringConfig,
      maximizedPrice,
      presentedPrice,
      presentedSavings,
      planType: plan,
      interval: plan.interval,
      amountWithoutDiscount: plan.amount,
      amountWithDiscount: getAmountWithDiscount(discountPercentage, plan.amount),
    };
    return presentablePlan;
  });
  if (presentablePlans?.length) {
    const mostExpensive = presentablePlans.reduce((max, obj) => {
      return obj.amountWithDiscount > max.amountWithDiscount ? obj : max;
    }, presentablePlans[0]); // start with the first element as the initial max
    mostExpensive.isPreferredOption = true;
  }

  const selectedPlan =
    !!selectedPlanInterval && presentablePlans?.find((p) => p.interval == selectedPlanInterval);

  const totalDueAmounts =
    selectedPlan && presentablePlans
      ? getTotalDueAmounts(presentablePlans, selectedPlan, discountPercentage)
      : undefined;

  const hasLifetimeOption = !!presentablePlans?.find((plan) => plan.interval === 'lifetime');
  const hasAnnualOption = !!presentablePlans?.find((plan) => plan.interval === 'year');

  return {
    plans: presentablePlans,
    currency: offering?.currency,
    promoCode,
    discountPercentage,
    selectedPlan,
    totalDueAmounts,
    hasLifetimeOption,
    hasAnnualOption,
  };
}

export const PlanIntervalConfigMap: Record<PlanInterval | 'free', PlanIntervalConfig> = {
  free: {
    title: 'free',
    subtitle: 'freeSubtitle',
    billingPeriod: 'perMonth',
    bullets: ['xFreeUnsubscribes'],
  },
  month: {
    title: 'monthly',
    subtitle: 'subscriptionSubtitle',
    billingPeriod: 'perMonthBilledMonthly',
    bullets: ['cancelWhenever'],
  },
  year: {
    title: 'yearly',
    subtitle: 'subscriptionSubtitle',
    billingPeriod: 'perMonthBilledYearly',
    bullets: ['cancelWhenever'],
  },
  lifetime: {
    title: 'lifetime',
    subtitle: 'lifetimeSubtitle',
    billingPeriod: 'oneTimePayment',
    bullets: ['payOnceUseForever'],
  },
};
