import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { db } from '../../db/db';
import Mailbox, { getCurrentMailbox } from '../../model/mailbox';
import MailboxSynchronizer from '../../services/mailboxSynchronizer/mailboxSynchronizer';
import { UserState, UserStats } from './userSlice.types';
import { MailboxStatus } from '../../services/mailboxSynchronizer/mailboxSynchronizer.types';
import {
  userHasActiveLicenses,
  userHasActiveRevenueCatEntitlement,
} from '../../utilities/paywallHelper';

export const initialState: UserState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSyncStatus: (state, action: PayloadAction<MailboxStatus>) => {
      state.mailboxSyncStatus = action.payload;
    },
    setUserStats: (state, action: PayloadAction<UserStats>) => {
      state.numberOfUnsubscribes = action.payload.unsubscribeCount || 0;
      state.numberOfDeletes = action.payload.trashedThreadCount || 0;
    },
    setHasLicense: (state, action: PayloadAction<boolean>) => {
      state.hasLicense = action.payload;
    },
    clearUser: (state) => {
      state = initialState;
    },
  },
});

export const { setSyncStatus, setUserStats, setHasLicense, clearUser } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMailboxSyncStatus = (state: RootState) => state.user.mailboxSyncStatus;
export const selectNumberOfUnsubscribes = (state: RootState) => state.user.numberOfUnsubscribes;
export const selectNumberOfDeletes = (state: RootState) => state.user.numberOfDeletes;
export const selectHasLicense = (state: RootState) => state.user.hasLicense;

export default userSlice.reducer;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const syncMailbox =
  (mailbox: Mailbox): AppThunk =>
  async (dispatch, getState) => {
    const syncStatus = selectMailboxSyncStatus(getState());

    if (syncStatus === MailboxStatus.SYNCING) {
      console.log('Mailbox already syncing!');
      return;
    }

    const synchronizer = new MailboxSynchronizer(mailbox);

    const updateMailboxStatus = (status: MailboxStatus) => {
      dispatch(setSyncStatus(status));
    };
    await synchronizer.sync(updateMailboxStatus);
  };

export const logoutUser = (): AppThunk => async (dispatch, getState) => {
  const mailboxes = await db.mailboxes.where({ is_current: 1 }).toArray();

  mailboxes.forEach(async (mailbox) => {
    await mailbox?.logout();
  });

  // TODO - stop any pending mailbox syncs

  dispatch(clearUser());
};

// If the user info is not yet set in the store, then fetch and store it
export const fetchCurrentUserInfo =
  (useCache?: boolean): AppThunk =>
  async (dispatch, getState) => {
    if (useCache) {
      const numberOfUnsubscribes = getState().user.numberOfUnsubscribes;
      const numberOfDeletes = getState().user.numberOfDeletes;
      const hasLicense = getState().user.hasLicense;

      if (
        numberOfUnsubscribes !== undefined &&
        numberOfDeletes !== undefined &&
        hasLicense !== undefined
      ) {
        return;
      }
    }

    const mailbox = await getCurrentMailbox();
    if (!mailbox) {
      return;
    }

    const user = await mailbox.getUser();
    if (!user) {
      throw new Error('User not found!');
    }

    dispatch(setUserStats(user));

    let hasLicense = userHasActiveLicenses(user);

    if (!hasLicense) {
      hasLicense = await userHasActiveRevenueCatEntitlement(mailbox);
    }

    if (!hasLicense && mailbox.email_address.endsWith('.edu')) {
      hasLicense = true;
    }

    dispatch(setHasLicense(hasLicense));
  };
