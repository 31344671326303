import React from 'react';

import PageHeaderInfo, { PageHeaderInfoProps } from './PageHeaderInfo';
import { useTranslation } from 'react-i18next';

export type IntroPageHeaderInfoProps = {
  flavor: LandingPageFlavorEnum;
};

export enum LandingPageFlavorEnum {
  CLEAN_EMAIL = 'clean_email',
  STOP_SPAM = 'stop_spam',
  UNSUBSCRIBE = 'unsubscribe',
  DELETE = 'delete',
  MARK_AS_READ = 'mark_as_read',
}

function IntroPageHeaderInfo(props: IntroPageHeaderInfoProps) {
  const { t } = useTranslation();

  let pageHeaderInfoProps: PageHeaderInfoProps = {
    headerText: t('fastestWayToCleanGmail'),
    subHeaderText: t('clearOutJunk') || undefined,
  };
  if (props.flavor === LandingPageFlavorEnum.CLEAN_EMAIL) {
    pageHeaderInfoProps = {
      headerText: t('fastestWayToCleanGmail'),
      subHeaderText: t('clearOutJunk') || undefined,
    };
  } else if (props.flavor === LandingPageFlavorEnum.STOP_SPAM) {
    pageHeaderInfoProps = {
      headerText: 'Block Spam Emails With 1 Click',
      subHeaderText: t('fastestWayToCleanInbox') || undefined,
    };
  } else if (props.flavor === LandingPageFlavorEnum.UNSUBSCRIBE) {
    pageHeaderInfoProps = {
      headerText: t('fastestWayToCleanGmail'),
      subHeaderText: t('unsubscribeWithOneClick') || undefined,
    };
  } else if (props.flavor === LandingPageFlavorEnum.DELETE) {
    pageHeaderInfoProps = {
      headerText: t('fastestWayToCleanGmail'),
      subHeaderText: t('deleteFast') || undefined,
    };
  } else if (props.flavor === LandingPageFlavorEnum.MARK_AS_READ) {
    pageHeaderInfoProps = {
      headerText: t('fastestWayToCleanGmail'),
      subHeaderText: t('markAsRead') || undefined,
    };
  }

  return <PageHeaderInfo {...pageHeaderInfoProps} noTopMargin></PageHeaderInfo>;
}

export default IntroPageHeaderInfo;
