import { WebViewInfo } from './services/webViewDetector/webViewDetector';

export const HIDE_EMAIL_BUNDLE_BUTTON_ICONS = false;

export const SUPPORT_LIST_SORTING = true;
export const FAKE_SIGN_IN = false;

export const SHOW_EMPTY_LIST = false;

export const NUMBER_OF_FREE_UNSUBSCRIBES = 10;
export const NUMBER_OF_FREE_DELETES = -1;

export const SHOW_MOBILE_ANIMATIONS = true;

export const WEBVIEW_INVITES = true;

export const SHOW_BULK_DELETE_TAB = false;

export const IMITATE_WEBVIEW: WebViewInfo | undefined = undefined;
// export const IMITATE_WEBVIEW: WebViewInfo | undefined = {
//   isInApp: true,
//   layoutConfig: TikTokWebView,
//   deviceOS: 'ios',
//   browser: 'tiktok',
// };
