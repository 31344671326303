import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack, Button, useTheme, useMediaQuery } from '@mui/material';
import ConnectGmailPromptBadge, {
  ConnectGmailPromptBadgeIcon,
  ConnectGmailPromptBadgeProps,
} from './ConnectGmailPromptBadge';
import banner_image from '../../assets/banner_image.svg';
import { useAppDispatch } from '../../app/hooks';
import { SCOPE_TYPE } from '../../services/credentialFactory';
import { logoutUser } from '../../features/user/userSlice';
import SignInButton from '../intro/onboarding/components/SignInButton';
import { useTranslation } from 'react-i18next';

export type ConnectGmailPromptProps = {
  username: string;
};

function ConnectGmailPrompt(props: ConnectGmailPromptProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const badge1Props: ConnectGmailPromptBadgeProps = {
    icon: ConnectGmailPromptBadgeIcon.VERIFIED,
    title: t('certifiedTitle'),
    description: t('certifiedSubtitle'),
  };

  const badge2Props: ConnectGmailPromptBadgeProps = {
    icon: ConnectGmailPromptBadgeIcon.TRANSPARENT,
    title: t('dataTitle'),
    description: t('dataSubtitle'),
  };

  const name: string = props.username || '';

  return (
    <Stack
      direction='column'
      alignItems='center'
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box
        margin={2}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 44 : isExtraSmall ? 28 : 36,
          marginBottom: isExtraSmall ? 0 : '-1rem',
        }}
      />
      <Stack
        flexGrow={1}
        justifyContent='center'
        alignItems='center'
        sx={{
          margin: aboveSmall ? '0rem' : '2rem 1rem',
        }}
      >
        <Typography
          variant='h4'
          component='h4'
          fontWeight={600}
          textAlign={'center'}
          sx={{
            fontSize: isExtraSmall ? '1.75rem' : aboveSmall ? '3rem' : '2rem',
          }}
        >
          {t('hi', { name })}
        </Typography>
        <Typography
          textAlign={'center'}
          sx={{
            margin: isExtraSmall ? '2rem 0rem' : '1.5rem 0rem',
            fontSize: isExtraSmall ? '1rem' : '1.25rem',
          }}
        >
          {t('connectMailboxUpsell')}
        </Typography>
        <Box>
          <SignInButton
            buttonText={t('connectGmail')}
            scopeType={SCOPE_TYPE.GMAIL_ACCESS}
            useGmailIcon={true}
          ></SignInButton>
        </Box>
        <Box>
          <Button
            variant='text'
            size='large'
            color='black'
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
            }}
            onClick={async () => {
              dispatch(logoutUser());
            }}
          >
            {t('useDifferentAccount')}
          </Button>
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          ...(aboveSmall
            ? {
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly',
              }
            : { padding: '0rem 1rem', flexDirection: 'column' }),
        }}
      >
        <ConnectGmailPromptBadge {...badge1Props} />
        <ConnectGmailPromptBadge {...badge2Props} />
      </Box>
    </Stack>
  );
}
export default ConnectGmailPrompt;
