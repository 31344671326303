import React from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  performKeepAction,
  performShowKeepSettingsAction,
  performShowUnsubscribeSettingsAction,
  performUnsubscribeAction,
  selectSessionListCount,
  selectUnsubscribeStatus,
} from './emailListsSlice';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { ButtonGroup, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { UnsubscribeStatus } from '../../model/subscription.types';
import { SubscriptionProperties } from '../../model/subscription';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PRIMARY_COLOR_TRANSPARENT, RED_COLOR_TRANSPARENT } from '../../theme';
import 'animate.css';
import { SHOW_MOBILE_ANIMATIONS } from '../../featureFlags';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { ListAction } from './emailLists.types';
import { useTranslation } from 'react-i18next';

export type EmailListActionButtonProps = {
  subscriptionProperties: SubscriptionProperties;
  actionType: ListAction;
  mailboxId: string;
};

export function EmailListActionButton(props: EmailListActionButtonProps) {
  const { subscriptionProperties: subscription, actionType, mailboxId } = props;
  const unsubscribeStatus = useAppSelector((state) =>
    selectUnsubscribeStatus(state, {
      listSender: subscription.email_address,
      mailboxId,
    })
  );

  const sessionListCount = useAppSelector(selectSessionListCount);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const isUnsubscribeButton = actionType === 'unsubscribe';

  const isUnsubscribed =
    subscription.unsubscribe_status === UnsubscribeStatus.Unsubscribed ||
    unsubscribeStatus === 'unsubscribed';

  const isKept =
    subscription.unsubscribe_status === UnsubscribeStatus.Kept || unsubscribeStatus === 'kept';

  const isFinished = isUnsubscribed || isKept;
  const isLoading = unsubscribeStatus === 'keeping' || unsubscribeStatus === 'unsubscribing';

  const isInitialState = !(isLoading || isFinished);

  let shouldHideButton = false;
  if (isUnsubscribeButton) {
    shouldHideButton = isKept || unsubscribeStatus === 'keeping';
  } else {
    shouldHideButton = isUnsubscribed || unsubscribeStatus === 'unsubscribing';
  }

  let shouldHideMoreOptionsButton = false;
  if (!isInitialState) {
    shouldHideMoreOptionsButton = true;
  }

  const color = isFinished ? 'primary' : actionType === 'keep' ? 'primary' : 'red';

  const { t } = useTranslation();

  let buttonText;

  if (isLoading) {
    buttonText = isUnsubscribeButton ? t('unsubscribing') : t('keeping');
  } else if (isFinished) {
    buttonText = isUnsubscribeButton ? t('unsubscribed') : t('kept');
  } else {
    buttonText = isUnsubscribeButton ? t('unsubscribe') : t('keep');
  }

  let fontSize = undefined;
  if (!shouldHideButton) {
    if (aboveMedium && isFinished) {
      fontSize = '1.25rem';
    } else if (isExtraSmall) {
      fontSize = '0.65rem';
    } else if (!aboveSmall) {
      fontSize = '0.75rem';
    }
  }

  const showAnimations = aboveSmall || SHOW_MOBILE_ANIMATIONS;

  return (
    <ButtonGroup
      variant={isInitialState ? 'outlined' : 'text'}
      aria-label='outlined button group'
      sx={{
        flexGrow: 1,
        ...(aboveSmall ? {} : { width: 0 }),
        ...(shouldHideButton ? { visibility: 'hidden' } : {}),
        justifyContent: 'center',
      }}
      className={isFinished && showAnimations ? 'animate__animated animate__rubberBand' : ''}
    >
      <LoadingButton
        variant={isInitialState ? 'outlined' : 'text'}
        color={color}
        size={aboveSmall ? 'medium' : 'small'}
        sx={{
          fontWeight: 600,
          whiteSpace: 'nowrap',
          ...(fontSize ? { fontSize } : {}),
          ...(aboveSmall || !isInitialState
            ? {}
            : {
                flexGrow: 1,
              }),
          '&:hover': {
            ...(isFinished
              ? {
                  background: 'none',
                }
              : {}),
          },
          ...(isInitialState
            ? {
                borderColor: `${
                  isUnsubscribeButton ? RED_COLOR_TRANSPARENT : PRIMARY_COLOR_TRANSPARENT
                } !important`,
                borderRight: 'none !important',
              }
            : { borderRight: 'none !important' }),
        }}
        startIcon={
          isLoading ? (
            <CheckIcon />
          ) : isFinished ? (
            <CheckIcon />
          ) : isUnsubscribeButton ? (
            <UnsubscribeIcon />
          ) : (
            <ThumbUpIcon />
          )
        }
        onClick={onClickActionButton(false, sessionListCount)}
        loading={isLoading && !shouldHideButton}
        loadingPosition='start'
      >
        {buttonText}
      </LoadingButton>
      <IconButton
        aria-label='more-options'
        size='small'
        color={isUnsubscribeButton ? 'error' : 'primary'}
        sx={{
          ...(shouldHideMoreOptionsButton ? { visibility: 'hidden' } : {}),
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: isUnsubscribeButton ? RED_COLOR_TRANSPARENT : PRIMARY_COLOR_TRANSPARENT,
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '0px',
          padding: aboveSmall ? '5px' : '5px 0px',
          backgroundColor: 'none',
        }}
        onClick={onClickActionButton(true, sessionListCount)}
      >
        <ExpandMoreIcon fontSize='inherit' />
      </IconButton>
    </ButtonGroup>
  );

  function onClickActionButton(
    isDropdown: boolean,
    sessionListCount: number | undefined
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    return () => {
      const showErrorToast = (errorMessage: string) => {
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          preventDuplicate: true,
          persist: false,
        });
      };

      if (isUnsubscribed || isLoading) {
        return;
      }

      if (isUnsubscribeButton) {
        // this is a high volume event, so we'll log it only once to save on Mixpanel costs
        if (!sessionListCount) {
          AnalyticsService.trackListEvent(EventName.CLICK_UNSUBSCRIBE, subscription.email_address);
        }

        if (isDropdown) {
          dispatch(
            performShowUnsubscribeSettingsAction({
              listSender: subscription.email_address,
              listName: subscription.display_name,
            })
          );
        } else {
          dispatch(
            performUnsubscribeAction(
              {
                listSender: subscription.email_address,
                listName: subscription.display_name,
              },
              showErrorToast,
              {
                alsoDelete: 'use_default',
                isFromSettings: false,
              }
            )
          );
        }
      } else {
        if (!sessionListCount) {
          AnalyticsService.trackListEvent(EventName.CLICK_KEEP, subscription.email_address);
        }

        if (isDropdown) {
          dispatch(
            performShowKeepSettingsAction({
              listSender: subscription.email_address,
              listName: subscription.display_name,
            })
          );
        } else {
          dispatch(
            performKeepAction(
              mailboxId,
              {
                listSender: subscription.email_address,
                listName: subscription.display_name,
              },
              showErrorToast,
              {
                alsoDelete: 'use_default',
                isFromSettings: false,
              }
            )
          );
        }
      }
    };
  }
}
